import { useStateMachine } from "little-state-machine";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../shared/components/Button";
import { getCoupon } from "../../../../shared/services/getCoupon";
import updateCheckoutState from "../../../../shared/utilities/updateCheckoutState";
import ReactGA from "react-ga4";

import "./styles.css";

const loadingSpinner = (
  <svg
    className="animate-spin mr-3 h-5 w-5"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);
function PromoCode({
  priceId,
  onPlanChosen,
  isAnnualId,
  selected,
}: {
  priceId: string;
  onPlanChosen: any;
  selected: boolean;
  isAnnualId?: boolean;
}) {
  const [loading, setLoading] = useState(false);
  const { state, actions } = useStateMachine({
    updateCheckoutState,
  });
  const [code, setCode] = useState(
    state.plan?.id === priceId ? state.coupon : ""
  );
  const [error, setError] = useState("");
  const { t } = useTranslation();

  const handleChangeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
    actions.updateCheckoutState({
      coupon: e.target.value,
    });
    setError("");
  };

  const actionAddCoupons = () => {
    setLoading(!loading);
    if (code && code.length > 0) {
      getCoupon({
        couponId: code,
      })
        .then(({ res }) => {
          setLoading(false);
          ReactGA.event("1.1_discount_code_step", {
            category: "discount_code_step",
            action: `uses_discount_${code}`,
            label: `${code} Discount Code`,
            //            value: 1,
          });

          if (
            res.currency &&
            res.currency !== state.plan?.currency?.toLowerCase()
          ) {
            setError("Coupon code does not use in your country");
          } else {
            actions.updateCheckoutState({
              detailCoupon: res,
            });
            onPlanChosen();
          }
        })
        .catch((error) => {
          setLoading(false);
          ReactGA.event({
            category: "Discount Code",
            action: `uses_discount_${code}_non_existent`,
            label: "Non existing Code",
            // value: 0,
          });
          setError("Coupon does not exist");
        });
    }
  };

  const chooplan = () => {
    if (code && code.length > 0) {
      actionAddCoupons();
    } else {
      onPlanChosen();
    }
  };

  return (
    <>
      <div className={true ? "wrap-promo-code" : "wrap-promo-code-no-input"}>
        <input
          placeholder={t("Add a promotional code")}
          value={code}
          onChange={handleChangeCode}
          type="text"
        />
        {code && code.length > 0 && (
          <label
            className="box-apply"
            htmlFor="toggle"
            onClick={actionAddCoupons}
            id={isAnnualId ? "B_signup_cuponannual" : "B_signup_cuponmonthly"}
          >
            {loading && loadingSpinner} Apply
          </label>
        )}
      </div>
      <div className="text-error" style={{ height: 18 }}>
        {error}
      </div>
      <div
        className="flex items-center justify-items-center justify-center cursor-pointer visited:text-[#53e9d7] hover:text-[#53e9d7]"
        onClick={chooplan}
        style={{ color: selected ? "#53e9d7" : "#ffffff" }}
      >
        <Button
          type="submit"
          id={isAnnualId ? "B_signup_annual" : "B_signup_monthly"}
        >
          {t("Select")}
        </Button>
      </div>
    </>
  );
}

export default PromoCode;
