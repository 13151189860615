import { useStateMachine } from "little-state-machine";
import React, { useState, useEffect } from "react";
import JazzHome from "../../StaticPage/JazzHome";

import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "react-slidedown/lib/slidedown.css";
import updateCheckoutState from "../../shared/utilities/updateCheckoutState";
import Steps from "./components/Steps";
import logo from "../../assets/images/Oh_jazz_black.svg";
import { useTranslation } from "react-i18next";

const SignupForm: React.FC = () => {
  const { state, actions } = useStateMachine({
    updateCheckoutState,
  });
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    if (state?.detailCoupon || state.step == 2) {
      setShow(true);
      setTimeout(() => {
        // setShow(false);
      }, 10000);
    }
  }, [state]);
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    /* on completed */
    if (
      queryParams.get("redirect_status") === "succeeded" &&
      state.step !== 5
    ) {
      actions.updateCheckoutState({ step: 5 });
    } else if (!state.step) {
      actions.updateCheckoutState({ step: 1 });
    }

    /* store affiliate ids */
    if (queryParams.get("affiliate")) {
      actions.updateCheckoutState({
        affiliateId: queryParams.get("affiliate"),
      });
    }
  }, [actions, state.step]);

  const { t } = useTranslation();
  console.log("state", state);
  const { detailCoupon, plan, step } = state;
  return (
    <>
      <JazzHome />
      <div className="flex flex-col justify-center w-full max-w-4xl	mx-auto pt-10 mb-20">
        <div className="mx-5">
          <div className="items-center flex flex-col">
            {/* <img src={logo} height="100" width="100" alt="oh-jazz" /> */}
            <h1 className="text-6xl tracking-wider text-watermelon text-center mt-10 mb-20 font-extrabold font-heading">
              {t("Create your account")}
            </h1>
          </div>
          <Steps />
        </div>
      </div>

      {step > 1 && step < 5 && (
        <>
          <Alert
            show={show}
            // variant="success"
            style={{
              backgroundColor: "#5EE9D7",
              width: "100%",
              margin: 0,
              borderRadius: 0,
              color: "#000000",
              position: "fixed",
              bottom: 0,
            }}
          >
            <Container>
              <Row className="align-items-center">
                <Col xs={2}>
                  <img src={logo} height="60" width="50" alt="oh-jazz" />
                </Col>
                <Col xs={5}>
                  <Row>
                    {/* <Col xs={12}>Subscription: {plan?.name}</Col> */}
                    <Col xs={12}>
                      {t("Standard Cost")}:{" "}
                      <b>
                        {detailCoupon ? (
                          <s>{plan?.price}</s>
                        ) : (
                          <> {plan?.price}</>
                        )}
                      </b>
                    </Col>
                  </Row>
                </Col>
                <Col xs={5}>
                  {detailCoupon && (
                    <Row>
                      <Col xs={12}>
                        {t("Discount")}: {detailCoupon?.percent_off}%{" "}
                        {t("applied")}
                      </Col>
                      <Col xs={12}>
                        {t("Your Cost")}:{" "}
                        <b>
                          {(plan?.price ?? 0) -
                            ((plan?.price ?? 0) *
                              (detailCoupon?.percent_off ?? 0)) /
                              100}
                        </b>{" "}
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </Container>
          </Alert>
        </>
      )}
    </>
  );
};

export default SignupForm;
