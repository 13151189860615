import generalTranslationsEn from "./en/general.json";
//import generalTranslationsDa from "./da/general.json";
import generalTranslationsJa from "./ja/general.json";
import generalTranslationsEs from "./es/general.json";
import generalTranslationsPt from "./pt/general.json";

const translations = {
  en: {
    general: generalTranslationsEn,
  },
  ja: {
    general: generalTranslationsJa,
  },
  es: {
    general: generalTranslationsEs,
  },
  pt: {
    general: generalTranslationsPt,
  },  
  
};

export default translations;
