import { useTranslation } from "react-i18next";
import annualImage from "../../../../../../assets/images/annual-green.jpg";
import monthlyImage from "../../../../../../assets/images/monthly-green.jpg";
import PromoCode from "../../../PaymentForm/PromoCode";
import Price from "../Price";
import "./styles.css";

export interface PlanType {
  id: string;
  name: string;
  price: number;
  currency: string;
  isAnnual?: boolean;
  onPlanChosen?: any;
}

export interface PlanProps extends PlanType {
  selected: boolean;
  onClick: () => void;
}

const Plan: React.FC<PlanProps> = ({
  id,
  name,
  price,
  currency,
  selected,
  isAnnual,
  onPlanChosen,
  onClick,
}) => {
  const { t } = useTranslation();
  return (
    <div onClick={onClick} className="wrap-box-plan">
      <img
        src={isAnnual ? annualImage : monthlyImage}
        alt={name}
        className="max-w-full max-h-full"
      />
      <div className="pt-6">
        <span className="text-5xl uppercase font-heading">
          {isAnnual ? t("Yearly Pass") : t("Monthly Pass")}
          <Price currency={currency}>{price}</Price>
        </span>
      </div>
      <p
        className="text-[#53e9d7] pb-4"
        style={{ display: isAnnual ? "block" : "none" }}
      >
        {t("Save")} 20%
      </p>
      <ul
        className="leading-8 dashed"
        style={{ paddingTop: isAnnual ? 0 : 39 }}
      >
        <li> {t("Access to all our content, live and on-demand")}</li>
        <li> {t("Unlimited viewing")}</li>
        <li> {t("Change or cancel anytime, no commitment")}</li>
        <li> {t("Where to watch: TV, Smartphones, tablets, PC")}</li>
        {/* {t("Soon available on App Store") && (
          <li> {t("Soon available on App Store")}</li>
        )} */}
      </ul>
      <PromoCode
        isAnnualId={isAnnual}
        priceId={id}
        onPlanChosen={onPlanChosen}
        selected={selected}
      />
    </div>
  );
};

export default Plan;
