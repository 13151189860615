import { useStateMachine } from "little-state-machine";
import React, { useEffect } from "react";
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./JazzHome.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import sitelogo from "../assets/images/Oh_jazz_white.svg";
import logo from "../assets/images/header-x.jpg";
import HikariLchihara from "../assets/images/HikariLchihara.jpg";
import PlayIcon from "../assets/images/playIcon.svg";

import JeremyRose from "../assets/images/JeremyRose.jpg";
import MarcoMarconi from "../assets/images/MarcoMarconi.jpg";
import RitaSatch from "../assets/images/RitaSatch.jpg";
import ChanoDominguez from "../assets/images/ChanoDominguez.jpg";
import { useTranslation } from "react-i18next";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


const SignupForm: React.FC = () => {
    const [modalShow, setModalShow] = React.useState(false);
    const [video, setVideo] = React.useState({videoLink:'',});
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
    }, []);

    const CustomModal = (props: any) => {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <video controls autoPlay>
                        <source src={video.videoLink} type="video/mp4" />
                        {/* <source src="movie.ogg" type="video/ogg" /> */}
                        Your browser does not support the video tag.
                    </video>
                </Modal.Body>
               
            </Modal>
        );
    }
    const { t } = useTranslation();
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    const handleModalOpen=(vLink:any)=>{
        setVideo({videoLink:vLink})
        setModalShow(true)
    
    }
    return (

        <div className="main-container">
            <Container fluid className="logo-cont">
                <Row>
                    <img src={sitelogo} className="logo-top"/>
                </Row>
            </Container>             
            <Container fluid className="hero-cont">
                <Row>
                    <h1>{t("LIVE JAZZ HAS A NEW HOME")}</h1>
                    <h2>{t("Turn your living room into a jazz lounge with the only live and on-demand jazz platform, and watch the best performances from iconic Jazz clubs around the world.")}</h2>
                    <p>{t("SUBSCRIBE, DISCOVER, SUPPORT")}</p>
                </Row>
            </Container>            
            <Container className="mt-5 mb-5">
                <Row>
                    <Col xs={12} sm={4} lg={4} className="mb-5" >
                        <Card className="card-container  h-100">
                            <Card.Body className="pb-5">
                                <Card.Title className="text-center card-header-text">{t("SUBSCRIBE")}</Card.Title>
                                <Card.Text className="text-center card-text">
                                    {t("Get access to new exclusive live shows every month and a growth library of exclusive content including concerts, playlists, masterclasses and interviews.")}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={4} lg={4} className="mb-5">
                        <Card className="card-container h-100" >
                            <Card.Body className="pb-5">
                                <Card.Title className="text-center card-header-text">{t("DISCOVER")}</Card.Title>
                                <Card.Text className="text-center card-text">
                                {t("Travel the world of Jazz and discover new artists and influences from every corner of the world, from venues in London, Tokyo, Melbourne, Madrid, Sydney and soon Sao Paulo.")}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={4} lg={4} className="mb-5">
                        <Card className="card-container h-100" >
                            <Card.Body className="pb-5">
                                <Card.Title className="text-center card-header-text">{t("SUPPORT")}</Card.Title>
                                {/* <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle> */}
                                <Card.Text className="text-center card-text pb-5">
                                    {t("Every subscription will directly support the global jazz community, with 35% of all revenue going to our partner artists and clubs.")}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </Container>
            <Container fluid className="mb-5">
                <Row gap="2">
                    <Carousel
                        infinite={true}
                        autoPlay={true}
                        autoPlaySpeed={3000}
                        keyBoardControl={true}
                        responsive={responsive}>

                        <Col xs={1} sm={3} lg={3} className="w-100 pr-5">
                            <Card className="border-0" >
                                <Card.Img className="imagePlay" variant="top" onClick={()=> handleModalOpen("https://static.ohjazz.tv/HikariIchihara.mp4")} src={HikariLchihara} />
                               
                                <Card.Body className="main-container p-0">
                                    <Card.Title >
                                        <h2 className="content-title">
                                            Hikari Ichihara
                                        </h2>
                                    </Card.Title>
                                    <Card.Subtitle className="mb-2 content-sub-title">BODY & SOUL, Tokyo</Card.Subtitle>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col xs={1} sm={3} lg={3} className="w-100 pr-5">
                            <Card className="border-0">
                                <Card.Img className="imagePlay" variant="top" onClick={()=> handleModalOpen("https://static.ohjazz.tv/RitaSatch.mp4")} src={RitaSatch} />
                                <Card.Body className="main-container p-0">
                                    <Card.Title >
                                        <h2 className="content-title">Rita Satch
                                        </h2>
                                    </Card.Title>
                                    <Card.Subtitle className="mb-2 content-sub-title">The JazzLab, Melbourne</Card.Subtitle>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={1} sm={3} lg={3} className="w-100 pr-5">
                            <Card className="border-0">
                                <Card.Img className="imagePlay" variant="top" onClick={()=>handleModalOpen("https://static.ohjazz.tv/MarcoMarconi.mp4")} src={MarcoMarconi} />
                                <Card.Body className="main-container p-0">
                                    <Card.Title >
                                        <h2 className="content-title">
                                            Marco Marconi FT. Emma Rawicz
                                        </h2>
                                    </Card.Title>
                                    <Card.Subtitle className="mb-2 content-sub-title">The 606 Club, London</Card.Subtitle>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={1} sm={3} lg={3} className="w-100 pr-5">
                            <Card className="border-0">
                                <Card.Img className="imagePlay" variant="top"onClick={()=>handleModalOpen("https://static.ohjazz.tv/ChanoDominguez.mp4")} src={ChanoDominguez} />
                                <Card.Body className="main-container p-0">
                                    <Card.Title >
                                        <h2 className="content-title">
                                            Chano Dominguez & Diego Amador
                                        </h2>
                                    </Card.Title>
                                    <Card.Subtitle className="mb-2 content-sub-title">Café Berlín, Madrid</Card.Subtitle>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={1} sm={3} lg={3} className="w-100 pr-5">
                            <Card className="border-0">
                                <Card.Img className="imagePlay" variant="top"  onClick={()=>handleModalOpen("https://static.ohjazz.tv/JeremyRose.mp4")} src={JeremyRose} />
                                <Card.Body className="main-container p-0">
                                    <Card.Title >
                                        <h2 className="content-title">Jeremy Rose Quartet </h2> </Card.Title>
                                    <Card.Subtitle className="mb-2 content-sub-title">Foundry 616, Sydney</Card.Subtitle>
                                </Card.Body>
                            </Card>
                        </Col>

                    </Carousel>
                </Row>
            </Container>


            <CustomModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </div>
    );
};

export default SignupForm;
