import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useStateMachine } from "little-state-machine";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../shared/components/Button";
import Typography from "../../../../shared/components/Typography";
import { updateStripeSub } from "../../../../shared/services/updateSubcription";
import updateCheckoutState from "../../../../shared/utilities/updateCheckoutState";
import ReactGA from "react-ga4";

interface PaymentFormProps {}

const PaymentForm: React.FC<PaymentFormProps> = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { state, actions } = useStateMachine({
    updateCheckoutState,
  });

  const { t } = useTranslation();

  const handleSubmit = async (event: React.FormEvent) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setIsLoading(true);
    setErrorMessage("");
    const result = await stripe
      .confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: "https://example.com",
        },
        redirect: "if_required",
      })
      .then((response) => {
        let paymentIntent = response.paymentIntent;
        if (
          state.stripe.subscriptionId &&
          state.stripe.customerId &&
          paymentIntent &&
          paymentIntent.payment_method
        ) {
          updateStripeSub({
            subcriptionId: state.stripe.subscriptionId,
            customerId: state.stripe.customerId,
            payment_method: paymentIntent.payment_method,
          }).then((res) => {
            console.log("update subscription success--->", res);
          });
          if (paymentIntent.status === "succeeded" && response.paymentIntent) {
            // tracking event for payment
            function addZeroes(num: String) {
              const dec = num.split(".")[1];
              const len = dec && dec.length > 2 ? dec.length : 2;
              return Number(num).toFixed(len);
            }
            // ReactGA.event({
            //   category: "step_payment",
            //   action: `step_plan_${state?.plan ? addZeroes(state?.plan?.price.toString()||"")  : ""}_selected`,
            //   label: `${state?.plan?.price}${state?.plan?.currency} Plan Selected`,
            //   value: 1,
            // })
            // ReactGA.event({
            //   category: "plan selected",
            //   action: state?.plan?.name || "",
            //   label: response?.paymentIntent.currency,
            //   value: response?.paymentIntent.amount,
            // })
            // ReactGA.event({
            //   category: "Transaction Id ",
            //   action: response?.paymentIntent.id,
            //   label: response?.paymentIntent.id,
            //   // value: response?.paymentIntent.amount ,
            // })
            // ReactGA.event({
            //   category: "Customer Id ",
            //   action: state?.stripe?.customerId,
            //   label: response?.paymentIntent.currency,
            //   // value: response?.paymentIntent.amount,
            // })
            // ReactGA.event({
            //   category: "subscription Id ",
            //   action: state?.stripe?.subscriptionId,
            //   label: response?.paymentIntent.currency,
            //   // value: response?.paymentIntent.amount,
            // })
            // ReactGA.event({
            //   category: "payment",
            //   action: `total amount ${response?.paymentIntent.amount}`,
            //   label: response?.paymentIntent.currency,
            //   value: response?.paymentIntent.amount,
            // })

            ReactGA.event("4_payment", {
              category: "payment",
              action: `plan_${
                state?.plan
                  ? addZeroes(state?.plan?.price.toString() || "")
                  : ""
              }_${state?.plan?.currency}_payed`,
              label: `${state?.plan?.price}${state?.plan?.currency} Plan Payed`,
              value: `${
                state?.plan
                  ? addZeroes(state?.plan?.price.toString() || "")
                  : ""
              }`,
              currency: `${state?.plan?.currency}`,
            });

            ReactGA.event("purchase", {
              category: "purchase",
              action: `plan_${
                state?.plan
                  ? addZeroes(state?.plan?.price.toString() || "")
                  : ""
              }_${state?.plan?.currency}_payed`,
              label: `${state?.plan?.price}${state?.plan?.currency} subscribed successfully`,
              currency: `${state?.plan?.currency}`,
              value: `${
                state?.plan
                  ? addZeroes(state?.plan?.price.toString() || "")
                  : ""
              }`,
            });

            // ShareASale
            localStorage.setItem(
              "orderDetails",
              JSON.stringify({
                id: response?.paymentIntent.id,
                amount: response?.paymentIntent.amount,
                currency: response?.paymentIntent.currency,
              })
            );
            window.location.href =
              process.env.REACT_APP_PUBLIC_URL +
              "/confirmation?redirect_status=succeeded";
          }
        }
        if (response.error) {
          setErrorMessage(response.error?.message || t("Something went wrong"));
          function addZeroes(num: String) {
            const dec = num.split(".")[1];
            const len = dec && dec.length > 2 ? dec.length : 2;
            return Number(num).toFixed(len);
          }
          ReactGA.event("4_payment", {
            category: "payment",
            action: `plan_${
              state?.plan ? addZeroes(state?.plan?.price.toString() || "") : ""
            }_${state?.plan?.currency}_failed`,
            label: "Payment Failed to process",
            value: 0,
          });
        }
      })
      .finally(() => setIsLoading(false));
  };
  return (
    <div className="mt-5">
      <form onSubmit={handleSubmit} id="myForm">
        <PaymentElement />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            loading={isLoading || !stripe || !elements}
            type="submit"
            id="B_signup_payment"
            className="mt-5"
          >
            {t("Subscribe")}
          </Button>
        </div>
        {errorMessage && (
          <Typography appearance="error" className="mt-5">
            {t(errorMessage)}
          </Typography>
        )}
      </form>
    </div>
  );
};

export default PaymentForm;
