import React from "react";
import SignupForm from "./features/SignupForm";
import { StoreProvider } from "./shared/store/checkout";
import "whatwg-fetch";
import ReactGA from "react-ga4";
// @ts-ignore
import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();

const TRACKING_ID = "G-19T718ZWNV";
ReactGA.initialize(TRACKING_ID);

ReactGA.initialize([
  {
    trackingId: TRACKING_ID,
    gaOptions: {
      titleCase: false,
    },
  },
]);

const App: React.FC = () => {
  ReactGA.initialize([
    {
      trackingId: TRACKING_ID,
      gaOptions: {
        titleCase: false,
      },
    },
  ]);
  return (
    <StoreProvider>
      <SignupForm />
    </StoreProvider>
  );
};

export default App;
