import classNames from "classnames";

interface StepDividerProps {
  step: number;
  active: boolean;
  currentStep: number;
}
const StepDivider: React.FC<StepDividerProps> = ({
  children,
  active,
  step,
  currentStep,
}) => (
  <div
    className={classNames("bg-stone-700 p-4 flex items-center mb-6", {
      "mb-6": active,
    })}
  >
    <div
      className={classNames(
        "text-black mr-2 rounded-full w-8 h-8 flex items-center justify-center text-sm",
        {
          "bg-white": !active,
          "bg-watermelon": active,
        }
      )}
    >
      {currentStep > step ? "✓" : step}
    </div>
    <h2
      className={classNames("flex items-center h2size", {
        "text-white": active,
      })}
    >
      {children}
    </h2>
  </div>
);

export default StepDivider;
